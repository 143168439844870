<template>
  <div class="start">
    <section class="bg-gray">
      <SkipNav skipid="#main" />
      <header>
        <TopBarApp
          ref="topbar"
        />
      </header>

      <!-- Start Test Page -->
      <main id="main" class="test-controller-container container-fluid">
        <div class="start-test-content">
          <h1 class="visually-hidden">Start Test Page</h1>
          <div>
            <StartPanel
              :title="this.test.title"
              :description="this.test.description"
              :max-items="this.test.count"
              @start="handleStart(index)"
            />
          </div>
        </div>
      </main>

    </section>
  </div>
</template>

<script>
import TopBarApp from '@/components/TopBarApp'
import StartPanel from '@/components/StartPanel'
import SkipNav from '@/components/SkipNav'
import axios from 'axios'

export default {
  name: 'Start',

  components: {
    TopBarApp,
    StartPanel,
    SkipNav
  },

  data () {
    return {
      test: null,
      token: null,
    }
  },

  methods: {

    initialize () {
      // Load the player token
      this.token = this.$route.params.token


      axios
        .get(`/v1/qti/player/${this.token}`)
        .then(response => {
          this.test = response.data?.data?.test
        })

    },

    handleStart () {
      this.$router.push({
        name:'PlayerRun',
        params: {
          token: this.token
        }
      })
    }

  },

  mounted () {
    this.initialize()
  }
}
</script>
