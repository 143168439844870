import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'

Vue.config.productionTip = false

// settings
const Settings = require('./settings.json');
const env = process.env.VUE_APP_AU_ENV || process.env.NODE_ENV;
Axios.defaults.baseURL = Settings[env].server_url;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
