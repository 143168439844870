import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start.vue'

Vue.use(VueRouter)

const routes = [

  // Platform based routes that use API calls to retrieve content 
  {
    path: '/:token',
    name: 'PlayerStart',
    component: Start,
    meta: {
      title: 'Start Test Page'
    }
  },
  {
    path: '/:token/run',
    name: 'PlayerRun',
    // route level code-splitting
    // this generates a separate chunk (test.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ '../views/TestRunner.vue'),
    meta: {
      title: 'Test Runner'
    }
  },

]


const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Test Runner Home'
  next()
});

export default router
